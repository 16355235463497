@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .swiper-custom-paginations {
            z-index: 2;
            width: auto !important;

            .swiper-pagination-bullet {
                width: 30px;
                height: 7px;
                opacity: 1;
                background: rgba($base-white, 0.3);
                @include border-radius(5px);
                @include trans;

                &.swiper-pagination-bullet-active {
                    background: $base-white;
                }
            }

        }
    }
}